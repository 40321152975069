<!--  -->
<template>
  <div class='fapiao round bj-col-white pad'>
    <el-tabs v-model="active" @tab-click="tabClick">
      <el-tab-pane label="开具发票" name="1"></el-tab-pane>
      <el-tab-pane label="查询发票" name="2"></el-tab-pane>
    </el-tabs>
    <div class="remark" v-if="active == 1">温馨提示：购课后，一年内提交开票信息！</div>
    <div class="list" v-if="list.length > 0">
      <div v-for="(item, index) in list" :key="index">
        <div>
          <span>{{active == '2' ? item : item.year}}</span>年执业药师继续教育课程
        </div>
        <el-button 
          round 
          plain 
          type="success" 
          class="self-button--success" 
          size="mini" 
          @click="handleOpen(item.year)" 
          v-if="active == '1' && item.state == 0">
          开具发票
        </el-button>
        <el-button 
          round 
          plain 
          type="info" 
          disabled
          size="mini" 
          v-else-if="active == '1'">
          已超过开票时间
        </el-button>
        <el-button round type="success" class="self-button--success" size="mini" @click="handleLook(item)" v-if="active == '2'">查看发票</el-button>
      </div>
    </div>
    <div class="hint" v-else>暂无更多</div>
  </div>
</template>

<script>
import Api from '../api/my'

export default {
  components: {

  },
  data() {
    return {
      active: '1',
      list: [],
      type: 0
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getFapiaoArr()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    handleOpen (item) {
      this.$router.push({name: 'KaiFaPiao', query: {year: item}})
    },
    handleLook (item) {
      this.$router.push({name: 'FaPiaoArr', query: {year: item}})
    },
    tabClick () {
      this.type = this.active == '1' ? 0 : 1
      this.getFapiaoArr()
    },
    getFapiaoArr () {
      Api.getFapiaoArr({
        type: this.type
      }).then(res => {
        if (res.data.code == 1) {
          this.list = this.active == '1' ? res.data.res : res.data.data
        } else {
          this.$message.error("发票列表获取失败")
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.fapiao {
  .remark {
    text-align: left;
    color: red;
  }
  /deep/ .el-tabs__item {
    font-size: 18px;
  }
  /deep/ .el-tabs__item.is-active {
    color: @default;
  }
  /deep/ .el-tabs__item:hover:hover {
    color: @default;
  }
  /deep/ .el-tabs__active-bar {
    background-color: @default;
  }
  .list {
    width: 98%;
    max-height: 600px;
    overflow-y: auto;
    padding: 20px 0 20px;
    &::-webkit-scrollbar{
      width:5px;
      height:5px;
      /**/
    }
    &::-webkit-scrollbar-track{
      background: rgb(239, 239, 239);
      border-radius:2px;
    }
    &::-webkit-scrollbar-thumb{
      background: #bfbfbf;
      border-radius:10px;
    }
    &::-webkit-scrollbar-thumb:hover{
      background: #333;
    }
    &::-webkit-scrollbar-corner{
      background: #179a16;
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;
      padding: 20px 0;
    }
  }
  .hint {
    color: #999;
    text-align: center;
  }
}
</style>